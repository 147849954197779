
<script lang="jsx">
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { 
  TitleNav as STitleNav, 
  TitleNavItem as STitleNavItem
} from '@shein/sui-mobile'
const { langPath } = gbCommonInfo

export default {
  name: 'ReturnCodDrawer',
  components: {
    STitleNav,
    STitleNavItem,
  },
  props: {
    /**
     * value 0 off 1 returnPolicy 2 codPolicy
     */
    value: {
      type: Number,
      default: 0
    },
    language: {
      type: Object,
      default () {
        return {}
      }
    },
    returnCodData: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data () {
    return {
      isShow: false,
      langPath
    }
  },
  computed: {
    newReturnCodData () {
      return this.value === 1 ? this.returnCodData.find(i => i.type_id == 1) || {} : this.returnCodData[0]
    }
  },
  watch: {
    value (newVal) {
      if(newVal) {
        newVal === 2 && daEventCenter.triggerNotice({
          daId: '1-6-4-24'
        })
        this.isShow = true
      } else {
        this.isShow = false
      }
    },
    isShow (newVal) {
      this.$emit('input', newVal ? this.value : 0 )
    }
  },
  mounted() {
    if (this.value) {
      this.isShow = true
    }
  },
  methods: {
    handleTrace (e) {
      if (this.value === 1 && e.target.nodeName.toUpperCase() == 'A') {
        daEventCenter.triggerNotice({
          daId: '1-6-4-9'
        })
      }
    }
  },
  render () {
    return (
      <s-drawer
        visible={this.isShow}
        on={{ 'update:visible': v => this.isShow = v }}
        no-header={true}
        type="full"
        direction="rtl"
      >
        <div class="mshe-z-transform1 transform-fixed mshe-bg-container">
          <s-title-nav>
            <s-title-nav-item
              slot="preblock"
              class="j-icon-back j-return-policy-back-detail"
              icon-class="suiiconfont return-header-icon sui_icon_nav_back_24px"
              tabindex="0"
              role="button"
              onClick={() => this.isShow = false}
            />
            { 
              this.newReturnCodData && this.newReturnCodData.title ?
                <h4 domPropsInnerHTML={this.newReturnCodData.title}></h4> :
                null
            }
          </s-title-nav>
          <section class="user-select-return-policy">
            {
              this.newReturnCodData && this.newReturnCodData.content ? 
                <div
                  onClick={this.handleTrace}
                  domPropsInnerHTML={this.newReturnCodData.content}
                ></div> : null
            }
          </section>
        </div>
      </s-drawer>
    )
  }
}
</script>

<style lang="less">
  /* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
  .user-select-return-policy {
    padding: 0.4rem;
    word-break: break-word;
    & p a{
      color: @sui_color_link;
    }
  }
  .return-header-icon {
    &.sui_icon_nav_back_24px {
      /* rw:begin */
      .font-dpr(48px) !important;/* stylelint-disable-line declaration-no-important */
    }
  }
  .return-header-title{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0;
    pointer-events: none;
    line-height: 1.17rem;
    .margin-l(0)!important;/* stylelint-disable-line declaration-no-important */
    & > h4 {
      /* rw:begin */
      font-family: Adieu;
      max-width: 100%!important;/* stylelint-disable-line declaration-no-important */
      font-size: 14px;
    }
  }
</style>
